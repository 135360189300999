@keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    20% {
      opacity: 0;
    }
  
    40% {
      opacity: 0.3;
    }
  
    60% {
      opacity: 0.5;
    }
  
    80% {
      opacity: 0.9;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes loading {
    0% {
        
        margin-left: 0;
        margin-right: 100%;
    }
  
    50% {
      margin-left: 0%;
      margin-right: 0%;
    }
  
    100% {      
        margin-left: 100%;
        margin-right: 0;
    }
  }

@-webkit-keyframes scale-up-hor-left {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
  }
  @keyframes scale-up-hor-left {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
  }
  
  

.loading-asset-varilux{
    background-image: url('../../../assets/png/varilux-download-bg.webp');
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    background-color: #ddd;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    &__container{
        height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    // transition: all 2s ease-in-out;
    margin-top: 12rem;
    color: white;

    h3{
        width: 35.7rem;
        text-align: center;
        padding-bottom: 1.5em;
        animation: fadeIn 0.4s ease-out;
    }
    .progress-container{
        display: flex;        
        width: 25rem;
        &__bar{
            overflow: hidden;
            width: 43pt;
            height: 6pt;
            border-radius: 3pt;
            background-color: $text-color-lighter;
            margin: 0 auto;

            .progress-value{
                -webkit-animation: scale-up-hor-left 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite forwards;
	            animation: scale-up-hor-left 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite forwards;
                border-radius: 3pt;
                background: white;
                height: 6pt;
                width: 100%;
                &.waiting{
                    background-color: $text-color-lighter;
                    animation: none;
                }
                &.done{
                    background-color: white;               
                    animation: fadeIn 0.4s ease-out;     
                }
            }
            
        }
    }
    }
    

  }
  @media only screen and (max-height: 767px) {
    .loading-asset-varilux{
      background-position: 0% 0%;
    }
}