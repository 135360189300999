.demonstration-pop-up {
    position: relative;
    width: 100%;
    height: 100%;

    &__header {
        // position: absolute;
        // top: 0;
        width: 100%;
        height: 10%;
        background-color: #e8e8e8;
    }

    &__videos-container {
        width: 100%;
        height: 78%;
        // background: green;
        display: flex;
        
        .video {
            width: 50%;
            height: 100%;
            // background: red;
            position: relative;

            & video {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            .show {
                transform: translateY(0%);
                opacity: 1;
            }
            .close {
                transform: translateY(200%);
                pointer-events: none;
                opacity: 0;
            }   
            .product-selection {
                z-index: 1;
                width: 100%;
                height: 35%;
                position: absolute;
                background: rgba(48, 59, 74, 0.8);
                transition: all .25s ease-in-out;
                bottom: 0px;
                color: white;


                .close-section {
                    display: flex;
                    justify-content: center;
                    padding: 2%;
                    
                    & span {
                        width: 45px;
                        height: 5px;
                        border-radius: 10px;
                        background-color: white;
                        cursor: pointer;
                    }
                }

                .products-container {
                    margin-top: -10px;
                    height: 83%;
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    padding: 20px;
                    text-align: center;
                    gap: 10px;

                    .product-name {
                        background: #5a6675;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        border-radius: 10px;
                        height: 50px;
                        cursor: pointer;
                    }

                    .selectedProduct {
                        background: linear-gradient(65.64deg, #E31D93 9.26%, #712FBF 53.21%, #6E51EB 88.89%);
                    }
                }

            }
            
            .selected-product {
                position: absolute;
                bottom: 40px;
                left: 50%;
                transform: translateX(-50%);
                width: 240px;
                height: 40px;
                font-size: 14px;
                background: linear-gradient(65.64deg, #E31D93 9.26%, #712FBF 53.21%, #6E51EB 88.89%);
                color: white;
                border-radius: 7px;
                display: flex;
                justify-content: space-around;
                align-items: center;

                .pencil-style {
                    width: 25px;
                    height: 25px;
                    margin-top: 3px;
        
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 12%;
        background-color: #96969c;
        padding: 20px 22px;


        .controls-container {
            width: 100%;
            height: 100%;
            // background: red;
            display: flex;
            justify-content: space-between;

            .controls {
                width: 50%;
                height: 40px;
                padding-left: 10px;

                img {
                    width: 40px;
                    height: 40px;
                    background: white;
                    margin-left: 5px;
                    transform: scale(-1, 1);
                    border-radius: 10px;
                    cursor: pointer;
                }

                .clicked {
                    background: linear-gradient(65.64deg, #E31D93 9.26%, #712FBF 53.21%, #6E51EB 88.89%);
                }
            }

            .disable {
                opacity: 0.25;
                pointer-events: none;
            }


            .caption {
                width: 50%;
                height: 40px;
                display: flex;
                padding-right: 25px;
                align-items: center;
                justify-content: end;
                color: white;
                font-size: 14px;
            }   
        }
    }
}